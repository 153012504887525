import { useService, useUser } from '@insight2profit/drive-app';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useUserPermissions } from 'shared/hooks';
import { usePricesInvalidator } from 'shared/providers';
import { IViewTemporalCustomerPricesPage } from 'shared/types';

export type CustomerPricesSubmitMutationParams = {
    oldViewRow: IViewTemporalCustomerPricesPage;
};

export function useTemporalCustomerPricesSubmitMutation() {
    const { temporalCustomerPricesService } = useService();
    const user = useUser();
    const { userApproverTier } = useUserPermissions();
    const { invalidateQuery } = usePricesInvalidator();
    const { enqueueSnackbar } = useSnackbar();

    const mutation = useMutation<void, Error, CustomerPricesSubmitMutationParams>({
        mutationKey: ['CustomerPricesSubmit'],
        mutationFn: async ({ oldViewRow }) => {
            await temporalCustomerPricesService.submitRow(oldViewRow, userApproverTier, user);
        },
        onSuccess: () => {
            enqueueSnackbar('Submit successfully completed', { variant: 'success' });
            invalidateQuery();
        },
        onError: error => {
            enqueueSnackbar(error.message, { variant: 'error' });
        },
    });

    const onSubmit = (oldViewRow: IViewTemporalCustomerPricesPage) => {
        mutation.mutate({
            oldViewRow,
        });
    };

    return { onSubmit, isLoading: mutation.isLoading, isSubmitAllowed: temporalCustomerPricesService.isSubmitAllowed };
}
